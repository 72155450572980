import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import Messages from '../../assets/img/messageBorders.js'

export default function Bio(props) {
    const { t } = useTranslation()
    return (
        <section className="bio" id="about">
            <div className="container">
                <h2>{t('bio.title')}</h2>
                <p dangerouslySetInnerHTML={{ __html: t('bio.content') }} />
                <div style={{ textAlign: 'center' }}>
                    <a href="https://www.instagram.com/stories/highlights/18051035278865043/" target="_blank">
                        {t('bio.sample')}
                    </a>
                </div>
                <button onClick={props.showForm}>
                    <Messages firstColor="#4c1632" secondColor="#852657" />
                </button>
            </div>
        </section>
    )
}
